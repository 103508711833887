import TeachingId from "./TeachingId";

export default class Teaching
{
  constructor() {
    this.teachingId = {};
    this.startDate = 0;
    this.endDate = 0;
    this.institutionAvailable = false;
    this.programAvailable = false;
    this.subjectAvailable = false;
  };

  initFromJson(json) {
    this.teachingId = TeachingId.createFromJson(json["id"]);
    this.startDate = json["startDate"];
    this.endDate = json["endDate"];
    this.institutionAvailable = json["institutionAvailable"];
    this.programAvailable = json["programAvailable"];
    this.subjectAvailable = json["subjectAvailable"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      teachingId: this.teachingId,
      startDate: this.startDate,
      endDate: this.endDate,
      institutionAvailable: this.institutionAvailable,
      programAvailable: this.programAvailable,
      subjectAvailable: this.subjectAvailable
    });

    return jsonString;
  };

  static compareByStartDateDesc(a, b) {
    return b.startDate - a.startDate;
  };

  static createFromJson(json)
  {
    var teaching = new Teaching();
    teaching.initFromJson(json);
    return teaching;
  };

}
