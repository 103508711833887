import Education from "./Education";
import Expertise from "./Expertise";
import Teaching from "./Teaching";
import ScgCase from "./ScgCase";
import expertiseModel from '../model/expertise.json';
import casesModel from '../model/cases.json';

export default class User
{
  constructor() {
    this.username = "";
    this.scgUsername = "";
    this.priority = 100;
    this.firstName = "";
    this.lastName = "";
    this.title = "";
    this.linkedInProfileUrl = "";
    this.profileUsername = "";
    this.profileImageUrl = "/resources/images/general/scg_logo_350x80.png"
    this.phoneNumber = "";
    this.faxNumber = "";
    this.emailAddress = "";
    this.vcardBio = "";
    this.myEducation = [];
    this.myScgCases =[];
    this.myExpertise = [];
    this.myTeaching = [];

    this.getDegree = this.getDegree.bind(this);
  };

  initFromJson(memberJson) {
    this.username = memberJson["username"];
    this.scgUsername = memberJson["scgUsername"];
    this.priority = memberJson["priority"];
    this.firstName = memberJson["firstName"];
    this.lastName = memberJson["lastName"];
    this.title = memberJson["title"];
    this.linkedInProfileUrl = memberJson["linkedInProfileUrl"];
    this.profileUsername = memberJson["profileUsername"];
    this.profileImageUrl = "/resources/images/users/" + this.username + "-profile.png"
    this.phoneNumber = memberJson["phoneNumber"];
    this.faxNumber = memberJson["faxNumber"];
    this.emailAddress = memberJson["emailAddress"];
    this.vcardBio = memberJson["vcardBio"];

    for (var i = 0; i < memberJson["myEducation"].length; i++) {
      var education = Education.createFromJson(memberJson["myEducation"][i]);
      this.myEducation.push(education);
    }

    for (i = 0; i < memberJson["myScgCases"].length; i++) {
      var caseJson = casesModel[memberJson['myScgCases'][i].id]
      this.myScgCases.push(ScgCase.createFromJson(caseJson));
    }

    for (i = 0; i < memberJson["myExpertise"].length; i++) {
      var expertise = expertiseModel[memberJson["myExpertise"][i]];
      if (expertise) {
        this.myExpertise.push(Expertise.createFromJson(expertiseModel[memberJson["myExpertise"][i]]));
      }
    }

    for (i = 0; i < memberJson["myTeaching"].length; i++) {
      if (expertise) {
        this.myTeaching.push(Teaching.createFromJson(memberJson["myTeaching"][i]));
      }
    }
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      username: this.username,
      scgUsername: this.scgUsername,
      priority: this.priority,
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      linkedInProfileUrl: this.linkedInProfileUrl,
      profileUsername: this.profileUsername,
      phoneNumber: this.phoneNumber,
      faxNumber: this.faxNumber,
      emailAddress: this.emailAddress,
      vcardBio: this.vcardBio
    });

    return jsonString;
  };

  static compareByPriority(a, b) {
    return a.priority - b.priority;
  };

  equals(user) {
    if (user) {
      if (this.username === user.username) {
        return true;
      }
    }
    return false;
  };

  getFormalName() {

    var fullName = this.firstName + " " + this.lastName;

    if (this.title !== "" && this.title !== null) {
      fullName += ", ";
      fullName += this.title;
    }

    if (this.getDegree() !== "") {
      fullName += ", ";
      fullName += this.getDegree();
    }

    return fullName;
  }

  getDegree() {

    let degrees = [
                    "A.B.",
                    "B.A.",
                    "B.S.",
                    "M.Sc.",
                    "M.S.",
                    "M.B.A.",
                    "Ph.D."
                  ];

    var highestDegreeIndex = -1;

    for(var i = 0; i < this.myEducation.length; i++) {
      let degreeIdx = degrees.indexOf(this.myEducation[i].educationId.degree);

      if (degreeIdx > highestDegreeIndex) {
        highestDegreeIndex = degreeIdx;
      }
    }
    if (highestDegreeIndex >= 0) {
      return degrees[highestDegreeIndex];
    }
    else {
      return "";
    }
  }


  static createFromJson(memberJson)
  {
    var user = new User();
    user.initFromJson(memberJson);
    return user;
  };

}
