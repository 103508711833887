export default class ScgCase
{
  constructor() {
    this.id = 0;
    this.expertiseId = "";
    this.name = "";
    this.longName = "";
    this.priority = 0;
    this.myScgWorkers = [];
  };

  initFromJson(caseJson) {
    this.id = caseJson["id"];
    this.expertiseId = caseJson["expertiseId"];
    this.name = caseJson["shortName"];
    this.longName = caseJson["longName"];
    this.priority = caseJson["priority"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      id: this.id,
      expertiseId: this.expertiseId,
      name: this.name,
      longName: this.longName,
      priority: this.priority
    });

    return jsonString;
  };

  static compareByPriority(a, b) {
    return a.priority - b.priority;
  };

  equals(scgCase) {
    if (scgCase) {
      if (this.id === scgCase.id) {
        return true;
      }
    }
    return false;
  };


  static createFromJson(caseJson)
  {
    var scgCase = new ScgCase();
    scgCase.initFromJson(caseJson);
    return scgCase;
  };

}
