import React from 'react';


class Guests extends React.Component {

  render() {

    return (
      <div className="container">
        <h2>Guests</h2>
      </div>
    );
  }
}

export default Guests;
