import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import withRouter from '../hooks/withRouter';
import usersModel from '../model/users.json';
import User from '../classes/User';
import Expertise from '../classes/Expertise';
import Education from '../classes/Education';
import Teaching from '../classes/Teaching';
import MemberCard from '../components/MemberCard';

class Member extends React.Component {

  constructor(props) {
    super(props);

    var usernameStr = props.location.pathname.member_id;
    console.log(props);

    if (usernameStr === undefined) {
      usernameStr = props.location.pathname.substring(1, props.location.pathname.length);
    }

    this.member = User.createFromJson(usersModel[usernameStr]);

    // User STILL not found
    if (this.member === undefined) {
      this.member = new User();
      this.member.firstName = "User";
      this.member.lastName = "Not Found";
    }
  }

  generateExpertiseList() {

    var expertiseList = [];

    this.member.myExpertise.sort(Expertise.compareByPriority);

    for (var i = 0; i < this.member.myExpertise.length; i++) {
      expertiseList.push(<div key={this.member.myExpertise[i].expertiseId}>{this.member.myExpertise[i].name}</div>);
    }

    return expertiseList;

  }

  generateUserCasesList() {

    var rows = [];

    let divStyle = {
      fontFamily: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
      marginTop: 0,
      marginBottom: 20,
    };


    for (var i = 0; i < this.member.myScgCases.length; i++){
      rows.push(<li key={this.member.myScgCases[i].id} style={divStyle}>{ parse(this.member.myScgCases[i].longName) }</li>);
    }

    return <ul>{rows}</ul>
  }

  generateEducationList() {

    var divs = [];

    this.member.myEducation.sort(Education.compareByGraduationDateDesc);

    for (var i = 0; i < this.member.myEducation.length; i++){

      var value = this.member.myEducation[i].educationId.institution + ", " + this.member.myEducation[i].educationId.degree;

      if (this.member.myEducation[i].educationId.field) {
        value += " in " + this.member.myEducation[i].educationId.field;
      }

      divs.push(
        <div key={i}>
          {value}
        </div>
      );
    }

    return divs;
  }

  generateTeachingList() {

    var divs = [];

    this.member.myTeaching.sort(Teaching.compareByStartDateDesc);

    for (var i = 0; i < this.member.myTeaching.length; i++){

      var value = this.member.myTeaching[i].teachingId.institution;

      if (this.member.myTeaching[i].teachingId.program) {
        value += ", " + this.member.myTeaching[i].teachingId.program;
      }

      if (this.member.myTeaching[i].teachingId.subject) {
        value += ", " + this.member.myTeaching[i].teachingId.subject;
      }

      divs.push(
        <div key={i}>
          {value}
        </div>
      );
    }

    return divs;
  }

  render() {

    let containerDiv = {
      marginTop: 20,
      marginBottom: 40
    }

    let titleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      fontSize: 26,
      fontWeight: 600,
      color: "#832924",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let suvbTitleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginTop: 20,
      marginBottom: 20,
      fontSize: 18,
      fontWeight: 500,
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 500
    }

    return (
      <div style={containerDiv}>
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={taglineDivStyle}>
                Research, analysis and expert testimony in complex business litigation and regulatory proceedings
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={titleDivStyle}>
                {this.member.getFormalName()}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div>{ parse(this.member.vcardBio) }</div>
              { this.member.myExpertise.length > 0 &&
                <div style={suvbTitleDivStyle}>
                  Expertise
                </div>
              }
              {this.generateExpertiseList()}

              { this.member.myScgCases.length > 0 &&
                <div style={suvbTitleDivStyle}>
                  Representative Cases
                </div>
              }
              {this.generateUserCasesList()}

              { this.member.myEducation.length > 0 &&
                <div style={suvbTitleDivStyle}>
                  Education
                </div>
              }
              {this.generateEducationList()}

              { this.member.myTeaching.length > 0 &&
                <div style={suvbTitleDivStyle}>
                  Teaching
                </div>
              }
              {this.generateTeachingList()}


            </Col>
            <Col md={4}>
              <MemberCard member={this.member} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Member);
