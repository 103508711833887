import usersModel from '../model/users.json';
import User from './User';

export default class Article
{
  constructor() {
    this.articleId = 0;
    this.creator = null;
    this.title = "";
    this.subTitle = "";
    this.dateAndTime = 0;
    this.articleAbstract = "";
    this.articleBody = "";
    this.photoUrl = "";
    this.externalArticleUrl = "";
  };

  initFromJson(articleJson) {
    this.articleId = articleJson["articleId"];
    this.creator = User.createFromJson(usersModel[articleJson["creator"]]);
    this.title = articleJson["title"];
    this.subTitle = articleJson["subTitle"];
    this.dateAndTime = articleJson["dateAndTime"];
    this.articleAbstract = articleJson["articleAbstract"];
    this.articleBody = articleJson["articleBody"];
    this.photoUrl = articleJson["photoUrl"];
    this.externalArticleUrl = articleJson["externalArticleUrl"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      articleId: this.articleId,
      creator: this.creator.username,
      title: this.title,
      subTitle: this.subTitle,
      dateAndTime: this.dateAndTime,
      articleAbstract: this.articleAbstract,
      articleBody: this.articleBody,
      photoUrl: this.photoUrl,
      externalArticleUrl: this.externalArticleUrl
    });

    return jsonString;
  };

  static compareByDate(a, b) {
    return a.dateAndTime - b.dateAndTime;
  };

  equals(article) {
    if (article) {
      if (this.articleId === article.articleId) {
        return true;
      }
    }
    return false;
  };


  static createFromJson(articleJson)
  {
    var article = new Article();
    article.initFromJson(articleJson);
    return article;
  };

}
