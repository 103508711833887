import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Container, Carousel, Image, Card, Row, Col } from 'react-bootstrap';
import "../css/Home.css";
import Expertise from "../classes/Expertise";
import User from "../classes/User";
import expertiseModel from '../model/expertise.json';
import usersModel from '../model/users.json';

class Home extends React.Component {

  constructor() {
    super();

    this.expertiseDict = {};
    this.usernames = [];

    var expKeys = Object.keys(expertiseModel);

    for (var i = 0; i < expKeys.length; i++) {
      var exp = Expertise.createFromJson(expertiseModel[expKeys[i]]);
      if (exp.priority > 1) {
        this.expertiseDict[exp.expertiseId] = exp;
      }
    }



    this.state = {

    }
  }

  generateCarouselItem(expertise) {

    let imageStyle = {
      width: "100%"
    }

    let captionStyle = {
      marginBottom: 20
    }

    return <Carousel.Item key={expertise.expertiseId}>
      <Image src={expertise.bannerImageUrl} style={imageStyle} responsive />
      <Carousel.Caption style={captionStyle}>
        <div>
          <div className="carouselBoxTitle">{expertise.name}</div>

        </div>
      </Carousel.Caption>
    </Carousel.Item>;

  }

  generateCarouselItems() {

    var items = [];
    var expKeys = Object.keys(this.expertiseDict);

    for (var i = 0; i < expKeys.length; i++) {
      var exp = this.expertiseDict[expKeys[i]];
      items.push(this.generateCarouselItem(exp));
    }

    return items;

  }

  getUserCases(user) {

    let listStyleDiv = {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 16
    }

    var divs = [];

    for (var i = 0; i < user.myScgCases.length; i++){
      divs.push(<div key={i} style={listStyleDiv}>{ parse(user.myScgCases[i].name) }</div>);
    }

    return divs;
  }

  generateProfilePics() {
    let imageBoxStyle = {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: "auto",
      marginRight: "auto",
      width: 200,
      border: 2,
      borderStyle: 'solid',
      borderColor: 'white',
      backgroundColor: "#ffffff"
    }

    var consultants = [];
    var divs = [];

    for (var key in usersModel) {
      let consultant = User.createFromJson(usersModel[key]);
      consultants.push(consultant);
    }

    consultants.sort(User.compareByPriority);

    for (var i = 0; i < consultants.length && i < 4; i++) {
      let consultant = consultants[i];
      var imgUrl = "/resources/images/users/" + consultant.username + "-profile.png";

      divs.push(
        <div key={consultant.username} style={imageBoxStyle}>
          <Link to={"/" + consultant.username}>
            <Card>
              <Card.Img variant="top" src={imgUrl} />
              <Card.Body>
                <Card.Title>{consultant.getFormalName()}</Card.Title>
                <Card.Text>
                  { this.getUserCases(consultant)}
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>

      )
    }


    return divs;
  }


  render() {
    let containerStyle = {
      display: "flex",
      flexDirection: 'row',
      flexWrap: "wrap",
      justifyContent: 'space-around',

    }

    return (
      <div>
        <div>
          <Carousel controls={false}>
            { this.generateCarouselItems() }
          </Carousel>
        </div>
        <div>
          <Container>
            <Row>
              <Col md={12} style={containerStyle}>
                {this.generateProfilePics()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    );
  }
}

export default Home;
