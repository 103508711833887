import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Home from "../pages/Home";
import ComingSoon from "../pages/ComingSoon";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Member from "../pages/Member";
import Members from "../pages/Members";
import News from "../pages/News";
import AllExpertise from "../pages/AllExpertise";
import ExpertisePage from "../pages/ExpertisePage";
import Guests from "../pages/Guests";

class Main extends Component {

  render() {


    return (
      <main>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Home />}/>
            <Route path='/coming_soon' element={<ComingSoon />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/news' element={<News />}/>
            <Route path='/contact' element={<Contact />}/>
            <Route path='/personnel' element={<Members />}/>
            <Route path='/members/:member_id?' element={<Member />}/>
            <Route path='/anitabellatorre' element={<Member />}/>
            <Route path='/blainenye' element={<Member />}/>
            <Route path='/zacharynye' element={<Member />}/>
            <Route path='/bethcharlesworth' element={<Member />}/>
            <Route path='/tavyronen' element={<Member />}/>
            <Route path='/fayefort' element={<Member />}/>
            <Route path='/ruthlambert' element={<Member />}/>
            <Route path='/davidbentley' element={<Member />}/>
            <Route path='/katystorch' element={<Member />}/>
            <Route path='/blainenyejr' element={<Member />}/>
            <Route exact path='/expertise' element={<AllExpertise />}/>
            <Route path='/expertise/:expertise_id?' element={<ExpertisePage />}/>
            <Route path='/guests' element={<Guests />}/>
          </Routes>
        </BrowserRouter>
      </main>
    );
  }
}

export default Main;
