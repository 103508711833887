import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';
import expertiseModel from '../model/expertise.json';
import Expertise from '../classes/Expertise';

class Member extends React.Component {

  render() {

    let econResearch = Expertise.createFromJson(expertiseModel["economic-research"]);
    let securitiesLitigation = Expertise.createFromJson(expertiseModel["securities-litigation"]);
    let insuranceEconomics = Expertise.createFromJson(expertiseModel["insurance-economics"]);
    let antitrustAndUnfairCompetition = Expertise.createFromJson(expertiseModel["antitrust-and-unfair-competition"]);
    let intellectualProperty = Expertise.createFromJson(expertiseModel["intellectual-property"]);
    let valuationEconomicDamages = Expertise.createFromJson(expertiseModel["valuation-and-general-damages"]);


    let containerDiv = {
      marginTop: 20,
      marginBottom: 40,
      fontFamily: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif"
    }

    let titleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      fontSize: 26,
      fontWeight: 600,
      color: "#832924",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 18,
      fontWeight: 400
    }

    let memberCardDiv = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }

    return (
      <div style={containerDiv}>
        <Container>
          <Row>
            <Col md={12}>
              <div style={titleDivStyle}>
                SCG provides the economic foundation
              </div>
              <div style={taglineDivStyle}>
                Drawing upon decades of project experience and outstanding academic credentials from leading universities, SCG provides clients with the concrete foundation necessary to debate economic issues in complex litigation and regulatory proceedings.
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/expertise/" + securitiesLitigation.expertiseId}>
                  <Card className="cardStyleExpertise">
                    <Card.Img variant="top" src={securitiesLitigation.imageUrl} />
                    <Card.Body>
                      <Card.Title className='cardStyleExpertiseTitle'>{securitiesLitigation.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/expertise/" + intellectualProperty.expertiseId}>
                  <Card className="cardStyleExpertise">
                    <Card.Img variant="top" src={intellectualProperty.imageUrl} />
                    <Card.Body>
                      <Card.Title className='cardStyleExpertiseTitle'>{intellectualProperty.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/expertise/" + insuranceEconomics.expertiseId}>
                  <Card className="cardStyleExpertise">
                    <Card.Img variant="top" src={insuranceEconomics.imageUrl} />
                    <Card.Body>
                      <Card.Title className='cardStyleExpertiseTitle'>{insuranceEconomics.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/expertise/" + antitrustAndUnfairCompetition.expertiseId}>
                  <Card className="cardStyleExpertise">
                    <Card.Img variant="top" src={antitrustAndUnfairCompetition.imageUrl} />
                    <Card.Body>
                      <Card.Title className='cardStyleExpertiseTitle'>{antitrustAndUnfairCompetition.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/expertise/" + valuationEconomicDamages.expertiseId}>
                  <Card className="cardStyleExpertise">
                    <Card.Img variant="top" src={valuationEconomicDamages.imageUrl} />
                    <Card.Body>
                      <Card.Title className='cardStyleExpertiseTitle'>{valuationEconomicDamages.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/expertise/" + econResearch.expertiseId}>
                  <Card className="cardStyleExpertise">
                    <Card.Img variant="top" src={econResearch.imageUrl} />
                    <Card.Body>
                      <Card.Title className='cardStyleExpertiseTitle'>{econResearch.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    );
  }
}

export default Member;
