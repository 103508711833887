export default class TeachingId
{
  constructor() {
    this.username = "";
    this.institution = "";
    this.program = "";
    this.subject = "";
  };

  initFromJson(json) {
    this.username = json["username"];
    this.institution = json["institution"];
    this.program = json["program"];
    this.subject = json["subject"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      username: this.username,
      institution: this.institution,
      program: this.program,
      subject: this.subject
    });

    return jsonString;
  };

  static createFromJson(json)
  {
    var teachingId = new TeachingId();
    teachingId.initFromJson(json);
    return teachingId;
  };

}
