import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


class Footer extends Component {

  render() {

    let logoContainerDiv = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30
    }

    let tagLineDiv = {
      marginTop: 10,
      fontSize: 18,
      fontWeight: 400
    }

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#333333',
      color: "#ababab",
      fontSize: 18,
      fontWeight: 400,
      padding: 30
    }

    let containerStyle = {
      display: "flex",
      flexDirection: 'row',
      flexWrap: "wrap",
      justifyContent: 'space-around',
      width: "80%"
    }

    let subSectionDiv = {
      margin: 10,
      width: 320
    }

    let subSectionTitleDiv = {
      fontSize: 20,
      fontWeight: 500,
      color: "#ffffff",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#444444",
      marginBottom: 10
    }

    let subSectionTextDiv = {
      fontSize: 14,
      fontWeight: 500
    }


    return (
      <footer>
        <div style={logoContainerDiv}>
          <a href="/"><Image src="/resources/images/general/scg_logo_350x80.png" responsive /></a>
          <div style={tagLineDiv}>
            Economic Analysis, Litigation Support, Expert Testimony
          </div>
        </div>
        <div style={taglineDivStyle}>
          <div style={containerStyle}>
            <div style={subSectionDiv}>
              <div style={subSectionTitleDiv}>
                Welcome to SCG
              </div>
              <div style={subSectionTextDiv}>
                SCG, Inc. provides research, analysis and expert testimony in complex business litigation and regulatory proceedings. SCG also provides investigation of economic issues of concern to business or government entities and offers conceptual as well as analytical solutions.
              </div>
            </div>
            <div style={subSectionDiv}>
              <div style={subSectionTitleDiv}>
                Reputation
              </div>
              <div style={subSectionTextDiv}>
                Clients have relied on SCG for expert analysis and opinion since 1981, including in a number of the most significant cases of the past twenty-five years.
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
