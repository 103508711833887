import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsFillTelephoneFill, BsFillEnvelopeFill } from 'react-icons/bs';
import { AiOutlineLink } from 'react-icons/ai';


class Contact extends React.Component {

  render() {

    let containerDiv = {
      marginTop: 20,
      marginBottom: 40
    }

    let titleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      fontSize: 26,
      fontWeight: 600,
      color: "#832924",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 500
    }

    let memberCardDiv = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let detailContainerDiv = {
      width: '100%',
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 10
    }

    let detailTextDiv = {
      marginLeft: 10,
      marginRight: 10
    }

    return (
      <div style={containerDiv}>
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={taglineDivStyle}>
                Research, analysis and expert testimony in complex business litigation and regulatory proceedings
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={titleDivStyle}>
                Contact Us
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={memberCardDiv}>
                <div style={detailContainerDiv}>
                  <BsFillTelephoneFill />
                  <div style={detailTextDiv}>650.298.0200</div>
                </div>
                <div style={detailContainerDiv}>
                  <BsFillEnvelopeFill />
                  <div style={detailTextDiv}>scg_info@scginc.com</div>
                </div>
                <div style={detailContainerDiv}>
                  <AiOutlineLink  />
                  <div style={detailTextDiv}><a href="https://www.linkedin.com/company/stanford-consulting-group/">Linked In</a></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contact;
