import React from 'react';


class About extends React.Component {

  render() {

    return (
      <div className="container">
        <h2>About</h2>
      </div>
    );
  }
}

export default About;
