export default class Expertise
{
  constructor() {

    this.expertiseId = "";
    this.name = "";
    this.description = "";
    this.descriptionShort = "";
    this.descriptionParagraphs = [];
    this.mainBodyHtml = "";
    this.experience = [];
    this.imageUrl = "";
    this.bannerImageUrl = "";
    this.firmExpertise = "";
    this.priority = 0;
    this.myScgWorkers = [];
  };

  initFromJson(expertiseJson) {
    this.expertiseId = expertiseJson["expertiseId"];
    this.name = expertiseJson["name"];
    this.description = expertiseJson["description"];
    this.descriptionShort = expertiseJson["descriptionShort"];
    this.descriptionParagraphs = expertiseJson["descriptionParagraphs"];
    this.mainBodyHtml = expertiseJson["mainBodyHtml"];
    this.experience = expertiseJson["experience"];
    this.imageUrl = expertiseJson["imageUrl"];
    this.bannerImageUrl = expertiseJson["bannerImageUrl"];
    this.firmExpertise = expertiseJson["firmExpertise"];
    this.priority = expertiseJson["priority"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      expertiseId: this.expertiseId,
      name: this.name,
      description: this.description,
      descriptionShort: this.descriptionShort,
      descriptionParagraphs: this.descriptionParagraphs,
      mainBodyHtml: this.mainBodyHtml,
      experience: this.experience,
      imageUrl: this.imageUrl,
      bannerImageUrl: this.bannerImageUrl,
      firmExpertise: this.firmExpertise,
      priority: this.priority
    });

    return jsonString;
  };

  static compareByPriority(a, b) {
    return b.priority - a.priority;
  };

  equals(expertise) {
    if (expertise) {
      if (this.expertiseId === expertise.expertiseId) {
        return true;
      }
    }
    return false;
  };


  static createFromJson(expertiseJson)
  {
    var expertise = new Expertise();
    expertise.initFromJson(expertiseJson);
    return expertise;
  };

}
