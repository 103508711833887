import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import "../css/scg.css";
import newsModel from '../model/news.json';
import Article from '../classes/Article';

class News extends React.Component {

  generateArticleLinks() {

    let container = {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 20
    }

    let abstractStyle = {
      fontSize: 14,
      fontWeight: 400
    }

    var articlesArray = [];

    for (let index in newsModel) {
      let article = Article.createFromJson(newsModel[index]);

      articlesArray.push(<div key={index} style={container}>
          <a className="news" href={process.env.PUBLIC_URL + article.externalArticleUrl}>{parse(article.title)}</a>
          <div style={abstractStyle}>
            {parse(article.articleAbstract)}
          </div>
        </div>);

    }

    return articlesArray
  }


  render() {

    let containerDiv = {
      marginTop: 20,
      marginBottom: 40
    }

    let titleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      fontSize: 26,
      fontWeight: 600,
      color: "#832924",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 500
    }

    return (
      <div style={containerDiv}>
        <Container>

          <Row>
            <Col md={12}>
              <div style={titleDivStyle}>
                SCG News
              </div>
            </Col>
          </Row>
          <Row style={{marginBottom: 20}}>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={taglineDivStyle}>
                SCG has provided the expert analysis underpinning some of the most significant cases in the past twenty-five years.
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
          {this.generateArticleLinks()}
        </Container>
      </div>

    );
  }
}

export default News;
