export default class EducationId
{
  constructor() {
    this.educationId = "";
    this.institution = "";
    this.degree = "";
    this.field = "";
  };

  initFromJson(json) {
    this.username = json["username"];
    this.institution = json["institution"];
    this.degree = json["degree"];
    this.field = json["field"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      username: this.username,
      institution: this.institution,
      degree: this.degree,
      field: this.field
    });

    return jsonString;
  };

  static createFromJson(json)
  {
    var educationId = new EducationId();
    educationId.initFromJson(json);
    return educationId;
  };

}
