import React, { Component } from 'react';
import { Nav, Navbar, Image, Container, Row, Col} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';


class Header extends Component {

  // Regular Nav Bar
  // <Nav pullRight>
  //   <NavItem eventKey={4} href="/personnel">Who We Are</NavItem>
  //   <NavItem eventKey={5} href="/expertise">Expertise</NavItem>
  //   <NavItem eventKey={3} href="/contact">Contact</NavItem>
  // </Nav>

  render() {

    let navBarStyle = {
      backgroundColor: '#490b07',
      marginBottom: 0,
      borderRadius: 0
    };

    const linkStyle = {
      color: '#ffffff'
    }

    let logoContainerDiv = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let logoColStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      marginTop: 10,
      marginBottom: 10
    }

    let linksColStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginTop: 10,
      marginBottom: 10
    }

    let tagLineDiv = {
      marginTop: 10,
      fontSize: 18,
      fontWeight: 400
    }

    return (
      <header>
        <div style={logoContainerDiv}>
          <Container>
            <Row style={{height: 'auto'}}>
              <Col md={6} style={logoColStyle}>
                <div>
                  <a href="/"><Image src="/resources/images/general/scg_logo_350x80.png" responsive /></a>
                </div>
                <div style={tagLineDiv}>
                  Economic Analysis, Litigation Support, Expert Testimony
                </div>
              </Col>
              <Col md={6} style={linksColStyle}>
                <div>
                  702 Marshall St, Suite 200
                </div>
                <div>
                  Redwood City, CA 94063
                </div>
                <div>
                  (650) 298-0200
                </div>
              </Col>
            </Row>
          </Container>


        </div>
        <Navbar style={navBarStyle}>
          <Container>
            <Navbar.Brand style={linkStyle} href="/"><FaHome /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link style={linkStyle} href="/personnel">Who We Are</Nav.Link>
                <Nav.Link style={linkStyle} href="/expertise">Expertise</Nav.Link>
                <Nav.Link style={linkStyle} href="/news">News</Nav.Link>
                <Nav.Link style={linkStyle} href="/contact">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

/*
        <Navbar style={navBarStyle}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Home Glyph</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <NavItem eventKey={1} href="/personnel">Who We Are</NavItem>
              <NavItem eventKey={2} href="/expertise">Expertise</NavItem>
              <NavItem eventKey={3} href="/news">News</NavItem>
              <NavItem eventKey={4} href="/contact">Contact</NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
*/
export default Header;
