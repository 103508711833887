import EducationId from "./EducationId";

export default class Education
{
  constructor() {
    this.educationId = {};
    this.comments = "";
    this.graduationDate = 0;
    this.degreeAvailable = false;
    this.fieldAvailable = false;
    this.commentsAvailable = false;
  };

  initFromJson(json) {
    this.educationId = EducationId.createFromJson(json["id"]);
    this.comments = json["comments"];
    this.graduationDate = json["graduationDate"];
    this.degreeAvailable = json["degreeAvailable"];
    this.fieldAvailable = json["fieldAvailable"];
    this.commentsAvailable = json["commentsAvailable"];
  };

  toJsonString() {
    const jsonString = JSON.stringify({
      educationId: this.educationId,
      comments: this.comments,
      graduationDate: this.graduationDate,
      degreeAvailable: this.degreeAvailable,
      fieldAvailable: this.fieldAvailable,
      commentsAvailable: this.commentsAvailable
    });

    return jsonString;
  };

  static compareByGraduationDateDesc(a, b) {
    return b.graduationDate - a.graduationDate;
  };

  static createFromJson(json)
  {
    var education = new Education();
    education.initFromJson(json);
    return education;
  };

}
