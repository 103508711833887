import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import usersModel from '../model/users.json';
import User from '../classes/User';

class Members extends React.Component {

  render() {

    let blainenye = User.createFromJson(usersModel["blainenye"]);
    let zacharynye = User.createFromJson(usersModel["zacharynye"]);
    // let tavyronen = User.createFromJson(usersModel["tavyronen"]);
    let bethcharlesworth = User.createFromJson(usersModel["bethcharlesworth"]);
    let fayefort = User.createFromJson(usersModel["fayefort"]);
    let ruthlambert = User.createFromJson(usersModel["ruthlambert"]);
    // let katystorch = User.createFromJson(usersModel["katystorch"]);
    let davidbentley = User.createFromJson(usersModel["davidbentley"]);
    let anitabellatorre = User.createFromJson(usersModel["anitabellatorre"]);

    let containerDiv = {
      marginTop: 20,
      marginBottom: 40
    }

    let titleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      fontSize: 26,
      fontWeight: 600,
      color: "#832924",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 500
    }

    let memberCardDiv = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }

    return (
      <div style={containerDiv}>
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={taglineDivStyle}>
                Research, analysis and expert testimony in complex business litigation and regulatory proceedings
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={titleDivStyle}>
                SCG Staff
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/" + zacharynye.username}>
                  <Card className="cardStyle">
                    <Card.Img variant="top" src={zacharynye.profileImageUrl} />
                    <Card.Body>
                      <Card.Title>{zacharynye.firstName} {zacharynye.lastName}, {zacharynye.getDegree()}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/" + fayefort.username}>
                  <Card className="cardStyle">
                    <Card.Img variant="top" src={fayefort.profileImageUrl} />
                    <Card.Body>
                      <Card.Title>{fayefort.firstName} {fayefort.lastName}, {fayefort.getDegree()}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/" + bethcharlesworth.username}>
                  <Card className="cardStyle">
                    <Card.Img variant="top" src={bethcharlesworth.profileImageUrl} />
                    <Card.Body>
                      <Card.Title>{bethcharlesworth.firstName} {bethcharlesworth.lastName}, {bethcharlesworth.getDegree()}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={memberCardDiv}>

              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/" + blainenye.username}>
                  <Card className="cardStyle">
                    <Card.Img variant="top" src={blainenye.profileImageUrl} />
                    <Card.Body>
                      <Card.Title>{blainenye.firstName} {blainenye.lastName}, {blainenye.getDegree()}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>

              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={memberCardDiv}>
              <Link to={"/" + ruthlambert.username}>
                <Card className="cardStyle">
                  <Card.Img variant="top" src={ruthlambert.profileImageUrl} />
                  <Card.Body>
                    <Card.Title>{ruthlambert.firstName} {ruthlambert.lastName}, {ruthlambert.getDegree()}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/" + davidbentley.username}>
                  <Card className="cardStyle">
                    <Card.Img variant="top" src={davidbentley.profileImageUrl} />
                    <Card.Body>
                      <Card.Title>{davidbentley.firstName} {davidbentley.lastName}, {davidbentley.getDegree()}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <div style={memberCardDiv}>
                <Link to={"/" + anitabellatorre.username}>
                  <Card className="cardStyle">
                    <Card.Img variant="top" src={anitabellatorre.profileImageUrl} />
                    <Card.Body>
                      <Card.Title>{anitabellatorre.firstName} {anitabellatorre.lastName}, {anitabellatorre.getDegree()}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    );
  }
}

export default Members;
