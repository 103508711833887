import React from 'react';


class ComingSoon extends React.Component {

  render() {

    return (
      <div className="container">
        <h2>Coming Soon</h2>
      </div>
    );
  }
}

export default ComingSoon;
