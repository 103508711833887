import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import parse from 'html-react-parser';
import withRouter from '../hooks/withRouter';
import expertiseModel from '../model/expertise.json';
import casesModel from '../model/cases.json';
import ScgCase from '../classes/ScgCase';

class ExpertisePage extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    this.expertise = expertiseModel[props.params.expertise_id];
    this.expertiseCases = [];
    this.fetchRelevantCases();
  }

  fetchRelevantCases() {
    var casesKeys = Object.keys(casesModel);

    let divStyle = {
      fontFamily: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
      marginTop: 0,
      marginBottom: 20,
    };

    for (var key in casesKeys) {
      var c = ScgCase.createFromJson(casesModel[casesKeys[key]]);
      if (c.expertiseId === this.expertise.expertiseId) {
        this.expertiseCases.push(<div key={c.id} style={divStyle}>{c.name}</div>);
      }
    }
  }

  getExperience() {

    var rows = [];

    let divStyle = {
      fontFamily: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
      marginTop: 0,
      marginBottom: 20,
    };


    for (var i = 0; i < this.expertise.experience.length; i++) {
      rows.push(<li key={i} style={divStyle}>{this.expertise.experience[i]}</li>);
    }

    return <ul>{rows}</ul>
  }

  getDescription() {
    var rows = [];

    let divStyle = {
      fontFamily: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
      marginTop: 0,
      marginBottom: 20,
    };


    for (var i = 0; i < this.expertise.descriptionParagraphs.length; i++) {
      rows.push(<div key={i} style={divStyle}>{this.expertise.descriptionParagraphs[i]}</div>);
    }

    return rows;
  }

  getLandmarkCases() {
    var casesKeys = Object.keys(casesModel);
    var rows = [];

    let divStyle = {
      fontFamily: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
      marginTop: 0,
      marginBottom: 20,
    };

    let suvbTitleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginTop: 20,
      marginBottom: 20,
      fontSize: 18,
      fontWeight: 500,
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    var landmarkCasesArray = [];

    for (var key in casesKeys) {
      var c = ScgCase.createFromJson(casesModel[casesKeys[key]]);
      if (c.expertiseId === this.expertise.expertiseId && c.priority < 100) {
        landmarkCasesArray.push(c);
      }
    }

    landmarkCasesArray.sort(ScgCase.compareByPriority);

    for (var i = 0; i < landmarkCasesArray.length; i++) {
      rows.push(<div key={landmarkCasesArray[i].id} style={divStyle}>{ parse(landmarkCasesArray[i].longName) }</div>);
    }


    if (rows.length > 0) {
      var casesDivRows = [<div key={"landmark-cases"} style={suvbTitleDivStyle}>Landmark Cases</div>];
      casesDivRows.push(rows);
      return casesDivRows
    }
    else {
      return rows;
    }
  }


  render() {

    let containerDiv = {
      marginTop: 20,
      marginBottom: 40
    }

    let titleDivStyle = {
      paddingTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      fontSize: 26,
      fontWeight: 600,
      color: "#832924",
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#d5d0c3"
    };

    let imageColDiv = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    };

    let taglineDivStyle = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 500
    };



    return (
      <div style={containerDiv}>
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={taglineDivStyle}>
                Research, analysis and expert testimony in complex business litigation and regulatory proceedings
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={titleDivStyle}>{this.expertise.name}</div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              { this.getDescription() }
              { this.getExperience() }
              { this.getLandmarkCases() }
            </Col>
            <Col md={4} style={imageColDiv}>
              <Image src={this.expertise.imageUrl} rounded />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(ExpertisePage);
