import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { BsFillTelephoneFill, BsFillEnvelopeFill } from 'react-icons/bs';
import { AiOutlineLink } from 'react-icons/ai';

class MemberCard extends Component {

  render() {

    const { member } = this.props;

    let memberCardDiv = {
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let profileImageStyle = {
      width: '100%',
      marginBottom: 20
    }

    let detailContainerDiv = {
      width: '90%',
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 10
    }

    let detailTextDiv = {
      marginLeft: 10,
      marginRight: 10
    }

    return (
      <div style={memberCardDiv}>
        <Image style={profileImageStyle} src={member.profileImageUrl} rounded />
        <div style={detailContainerDiv}>
          <BsFillTelephoneFill />
          <div style={detailTextDiv}>{member.phoneNumber}</div>
        </div>
        <div style={detailContainerDiv}>
          <BsFillEnvelopeFill />
          <div style={detailTextDiv}>{member.emailAddress}</div>
        </div>
        { member.linkedInProfileUrl &&
        <div style={detailContainerDiv}>
          <AiOutlineLink  />
          <div style={detailTextDiv}><a href={member.linkedInProfileUrl}>Linked In</a></div>
        </div>
      }
      </div>
    );
  }
}

export default MemberCard;
